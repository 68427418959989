<template>
<div>
  <!-- 面包屑导航 -->
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>配件列表</el-breadcrumb-item>
    <el-breadcrumb-item>品牌列表</el-breadcrumb-item>
  </el-breadcrumb>
  <!--    内容开始-->
  <el-card class="box-card">
    <el-button type="primary" @click='opentan()'>添加信息</el-button>
<!--    列表开始-->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%">
      <el-table-column
        prop="name"
        label="品牌名称"
        >
      </el-table-column>
      <el-table-column label="操作" width="180px">
        <template slot-scope="scope">
          <!-- 修改按钮 -->
          <el-button size="mini" type="primary" icon="el-icon-edit" @click='editfun(scope.row)'></el-button>
          <!-- <el-button size="mini" type="success" icon="el-icon-view" @click='lookfun(scope.row.id)'></el-button> -->
          <el-button size="mini" type="danger" icon="el-icon-delete" @click='delfun(scope.row.id)'></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--      分页-->
    <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
  </el-card>
<!--  弹窗内容-->
  <el-dialog
    title="详情"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose">
    <el-form ref="dform" :model="dform" label-width="80px" :rules="drules">
      <el-form-item label="品牌名称" prop="name">
        <el-input v-model="dform.name"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="cancelfun()">取 消</el-button>
    <el-button v-if="tantype!=='look'" type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确 定</el-button>
  </span>
  </el-dialog>
</div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import { Addbrand, Delbrand, Editbrand, GetBraandsList, GetOnebrand } from '../../../api/vehicle/autoconfigure'

export default {
  components: {
    Fenye
  },
  data() {
    return {
      dialogVisible: false, // 弹窗控制
      dform: {
        name: ''
      },
      // 弹窗按钮的状态
      tantype: 'add',
      // 验证规则
      drules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ]

      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      // 列表数据
      tableData: []
    }
  },
  mounted() {
    this.getbrandlistfun()
  },
  methods: {
    bianzhi(newSize) { // 分页值变化
      this.pageData.size = newSize
      this.getbrandlistfun()
    },
    bianye(newPape) { // 分页页数变化
      this.pageData.page = newPape
      this.getbrandlistfun()
    },
    editfun(ev) { // 打开编辑
      // 赋值
      this.dform = JSON.parse(JSON.stringify(ev))
      // 修改属性为修改
      this.tantype = 'edit'
      // 打开弹窗
      this.opentan()
    },
    lookfun(ld) { // 打开查看
      console.log(ld)
      this.onebrandfun(ld)
      // 修改属性为修改
      this.tantype = 'look'
      // 打开弹窗
      this.opentan()
    },
    delfun(id) { // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delbrandfun(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 打开弹窗
    opentan() {
      this.dialogVisible = true
    },
    // 确定添加
    addgoto(type) {
      switch (type) {
        case 'add':
          console.log(type)
          this.$refs['dform'].validate(v => {
            if (v) {
              this.addbrandfun(this.dform)
            }
          })
          break
        case 'edit':
          this.$refs['dform'].validate(v => {
            if (v) {
              this.editbrandfun(this.dform)
            }
          })
          console.log(type)
          break
      }
    },
    // 取消
    cancelfun() {
      this.handleClose()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.dform = {}
      this.tantype = 'add'
    },
    // 请求区ლ(´ڡ`ლ)
    // 获取品牌
    async getbrandlistfun() {
      const { data } = await GetBraandsList(this.pageData)
      this.getanpostfun(data, '获取')
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page
    },
    // 添加品牌
    async addbrandfun(v) {
      const { data } = await Addbrand(v)
      this.getanpostfun(data, '添加', this.getbrandlistfun())
    },
    // 删除品牌
    async delbrandfun(id) {
      const { data } = await Delbrand({ id })
      this.getanpostfun(data, '删除', this.getbrandlistfun())
    },
    async editbrandfun(ev) {
      const { data } = await Editbrand(ev)
      this.getanpostfun(data, '修改', this.getbrandlistfun())
    },
    async onebrandfun(id) {
      const { data } = await GetOnebrand({id})
     this.dform = data.data

    },

    // 请求区结束Ψ(￣∀￣)Ψ
    // 公共方法区└(^o^)┘└(^o^)┘
    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        this.handleClose()
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>
<style scoped>

</style>
